// LoginPage.tsx
import React from 'react';
import { auth, provider } from './firebase';
import { signInWithPopup } from '@firebase/auth';
import { Button, Card } from 'flowbite-react';


const LoginPage: React.FC = () => {
  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
  };

  return (
    <div>
      <Card>
      <div className="mb-4 flex items-center justify-center">
      <div className='text-black text-3xl'>BST Admin Login</div>
      </div>
    
      <Button onClick={signInWithGoogle}>Sign in with Google</Button>
      </Card>
    </div>
  );
};

export default LoginPage;
